import { CdsButton } from '@circutor/react-ui'
import { Modal, MODAL_SIZES } from '@/components/Modal'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import styles from './RecoverPassword.module.scss'
import { useRecoverPassword } from '@/hooks/user/useRecoverPassword'
import RecoverPasswordForm, {
  RecoverPasswordData,
} from './components/RecoverPasswordForm'

import TextHtmlToNode from '@/components/TextHtmlToNode'
export default function RecoverPassword() {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)
  const [recoverData, setRecoverData] = useState<RecoverPasswordData | null>(
    null,
  )
  const { mutate: recover, isPending } = useRecoverPassword()

  const handleRecoverPassword = () => {
    if (recoverData) {
      recover(recoverData.email, {
        onSuccess: () => {
          setSuccessMessage(true)
          setRecoverData(null)
        },
        onError: () => {
          setSuccessMessage(true)
          setRecoverData(null)
        },
      })
    }
  }

  return (
    <>
      <div className={styles.activator}>
        <button
          aria-label={`recover password open modal`}
          onClick={() => {
            setOpenModal(true)
          }}
        >
          {t('userAccount.forgetPassword')}
        </button>
      </div>

      <Modal
        maskClosable={successMessage}
        open={openModal}
        onClose={() => {
          setOpenModal(false)
          setSuccessMessage(false)
        }}
        headerText={t('userAccount.recoverPassword')}
        headertIcon={'cds-ph-user'}
        size={MODAL_SIZES.small}
        footer={
          <div className={styles['modal__footer']}>
            {successMessage ? (
              <CdsButton
                key='closeModal'
                type='button'
                buttonType='secondary'
                onClick={() => {
                  setOpenModal(false)
                  setSuccessMessage(false)
                }}
              >
                {t('common.close')}
              </CdsButton>
            ) : (
              <CdsButton
                key='recoverPassword'
                icon='cds-ph-arrow-right'
                iconPosition='right'
                aria-label='recovery password'
                onClick={handleRecoverPassword}
                loading={isPending}
                disabled={!recoverData}
              >
                {t('common.send')}
              </CdsButton>
            )}
          </div>
        }
      >
        <div className={styles['modal__content']}>
          {successMessage ? (
            <p>{t('userAccount.recoverPasswordRequesSended')}</p>
          ) : (
            <>
              <TextHtmlToNode textHtml={t('userAccount.recoverPasswordInfo')} />

              <RecoverPasswordForm
                onChanged={(values: RecoverPasswordData | null) => {
                  setRecoverData(values)
                }}
              />
            </>
          )}
        </div>
      </Modal>
    </>
  )
}
