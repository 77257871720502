import { Modal as AntdModal } from 'antd'

import { Icon } from '@/components/Icon'

import styles from './modal.module.scss'

export const MODAL_SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
} as const

type ModalSize = (typeof MODAL_SIZES)[keyof typeof MODAL_SIZES]

type ModalProps = {
  open: boolean
  size?: ModalSize
  closable?: boolean
  onClose?: Function
  headerText?: string
  headertIcon?: string
  children: React.ReactNode
  footer?: React.ReactNode
  maskClosable?: boolean
  'data-cy'?: string
}

const sizeObject: Record<ModalSize, { maxWidth?: string }> = {
  [MODAL_SIZES.small]: { maxWidth: '40rem' },
  [MODAL_SIZES.medium]: { maxWidth: '65rem' },
  [MODAL_SIZES.large]: { maxWidth: '90rem' },
}

export function Modal({
  closable = true,
  size = MODAL_SIZES.small,
  onClose,
  open,
  headerText,
  headertIcon,
  footer,
  maskClosable,
  children: content,
  ...restProps
}: ModalProps) {
  const dataCy = restProps['data-cy'] || null
  return (
    <AntdModal
      data-cy={dataCy}
      centered
      closable={false}
      open={open}
      onCancel={() => onClose && onClose()}
      className={styles['wks-modal']}
      destroyOnClose={true}
      footer={null}
      width={'calc(100% - 2rem)'}
      style={{
        maxWidth: sizeObject[size].maxWidth,
      }}
      maskClosable={maskClosable}
    >
      <div className={styles['wks-modal']}>
        <div className={styles['wks-modal__header']}>
          <div className={styles['wks-modal__header__title']}>
            {headertIcon && <Icon icon={headertIcon} />}
            <h4>{headerText}</h4>
          </div>
          {closable && (
            <Icon icon='cds-ph-x' onClick={() => onClose && onClose()} />
          )}
        </div>
        <div className={styles['wks-modal__content']}>{content}</div>
        <div className={styles['wks-modal__footer']}>{footer}</div>
      </div>
    </AntdModal>
  )
}
