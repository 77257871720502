import { Form } from 'antd'
import { Rule } from 'antd/es/form'
import { useTranslation } from 'react-i18next'

import FormItem from '@/app/components/FormItem'

import styles from './RecoverPasswordForm.module.scss'
import { Icon } from '@/components/Icon'

export type RecoverPasswordData = {
  email: string
}

export default function RecoverPasswordForm({
  onChanged,
}: {
  onChanged: (data: RecoverPasswordData | null) => void
}) {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const validateMessages = {
    required: t('common.requiredField'),
  }

  const initialValues = {
    email: null,
  }

  const NOT_BLANK_REGEX = /^(?!\s*$).+/
  const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4}$/
  const NOT_BLANK_RULE = {
    type: 'string',
    pattern: NOT_BLANK_REGEX,
    message: t('common.requiredField'),
  } as Rule
  const EMAIL_RULE = {
    type: 'string',
    pattern: EMAIL_REGEX,
    message: t('feedback.invalidEmail'),
  } as Rule
  const REQUIRED_RULE = {
    required: true,
    message: t('common.requiredField'),
  } as Rule

  return (
    <div className={styles['container']}>
      <Form
        form={form}
        initialValues={initialValues}
        validateMessages={validateMessages}
        onValuesChange={() => {
          form
            .validateFields(Object.keys(initialValues), {
              validateOnly: true,
            })
            .catch(
              (validationResult: {
                values: RecoverPasswordData
                errorFields: any[]
              }) => {
                if (validationResult.errorFields?.length) {
                  onChanged(null)
                } else {
                  onChanged({ ...validationResult.values })
                }
              },
            )
        }}
      >
        <div className={styles['inputs-wrapper']}>
          <div>
            <FormItem
              item={{
                label: '',
                element: 'Input',
                key: 'email',
                placeholder: t('userAccount.email'),
                required: false,
                maxLength: 64,
                rules: [NOT_BLANK_RULE, REQUIRED_RULE, EMAIL_RULE],
              }}
            />
          </div>
        </div>
      </Form>
    </div>
  )
}
