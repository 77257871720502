import { default as httpClient } from '../httpClient/httpClientWithInterceptors'

const BASE = 'user'

export async function register(userData: {
  acceptCommercialNotifications: boolean
  companyActivity: string
  companyName: string
  companyPosition?: string
  country: string
  email: string
  firstName: string
  language: string
  lastName: string
  password: string
  phone?: string
  state: string
}) {
  const body = {
    ...userData,
    flagWKS: true,
  }
  return httpClient.fetch(`${BASE}/register`, {
    method: 'POST',
    body: JSON.stringify(body),
  })
}

export async function login(credentials: {
  email: string
  password: string
}) {
  const body = {
    username: credentials.email,
    password: credentials.password
  }

  const response = await httpClient.fetch(`${BASE}/login`, {
    method: 'POST',
    body: JSON.stringify(body),
  })

  return response.data.token
}

export function recoverPasswordByEmail(email: string) {
  const body = {
    email,
  }
  const queryParamsString = new URLSearchParams(body)
  return httpClient.fetch(`${BASE}/resetPasswordByEmail?${queryParamsString}`, {
    method: 'POST',
    body: JSON.stringify({})
  })
}

export function restorePasswordByResetToken(resetToken: string, newPassword: string) {
  const body = {
    resetToken,
    password: newPassword
  }

  return httpClient.fetch(`${BASE}/resetPassword`, {
    method: 'POST',
    body: JSON.stringify(body)
  })

}