'use client'

import { Form, Input, InputNumber, Select } from 'antd'
import { Rule } from 'antd/lib/form'
import styles from './formItem.module.scss'
import { ReactNode, useEffect, useMemo, useState } from 'react'

type SelectOptions = {
  label: string
  value: string
}

export type FormItemType = {
  label: string | ReactNode
  element: 'Input' | 'Select' | 'InputSecret' | 'InputNumber' | 'Textarea'
  key: string
  placeholder?: string
  disabled?: boolean
  options?: SelectOptions[] | undefined
  required?: boolean
  mode?: 'multiple'
  visible?: boolean
  rules?: Rule[]
  maxLength?: number
  min?: number
  max?: number
  value?: any
  prefix?: ReactNode
  selectSuffixIcon?: ReactNode
  inputOnPressEnter?: () => void
}

export default function FormItem({ item }: { item: FormItemType }) {
  const form = Form.useFormInstance()
  const value = Form.useWatch([], form)
  const [error, setError] = useState<string[]>()
  const defaultError = form.getFieldError(item.key)

  const errorMemo = useMemo(() => {
    return error?.length ? error : defaultError
  }, [defaultError, error])

  const handlerOnPressEnter = () => {
    form.validateFields([item.key]).then(
      () => {
        setError([])
        item.inputOnPressEnter && item.inputOnPressEnter()
      },
      () => {
        const errorField = form.getFieldError(item.key)
        setError(errorField)
      },
    )
  }

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setError([])
      },
      () => {
        const errorField = form.getFieldError(item.key)
        setError(errorField)
      },
    )
  }, [form, item.key, value])

  return (
    <div className={styles.inputContainer} data-testid='formItemContainer'>
      <p className={styles.inputLabel}>{item.label}</p>
      <Form.Item
        name={item.key}
        noStyle
        rules={[{ required: item.required ?? false }, ...(item.rules || [])]}
      >
        <>
          {item.element === 'Input' && (
            <Input
              aria-label={item.key}
              placeholder={item.placeholder}
              className={styles.cdsTextfieldField}
              type='input'
              disabled={item.disabled}
              data-testid={`formItemInput-${item.key}`}
              maxLength={item.maxLength}
              value={value}
              prefix={item.prefix}
              onPressEnter={handlerOnPressEnter}
            />
          )}
          {item.element === 'Textarea' && (
            <Input.TextArea
              aria-label={item.key}
              placeholder={item.placeholder}
              className={styles.cdsTextfieldField}
              disabled={item.disabled}
              data-testid={`formItemInput-${item.key}`}
              maxLength={item.maxLength}
              value={value}
              onPressEnter={handlerOnPressEnter}
            />
          )}
          {item.element === 'Select' && (
            <Select
              aria-label={item.key}
              showSearch
              mode={item.mode}
              maxTagCount='responsive'
              placeholder={item.placeholder}
              style={{ borderRadius: '0.25rem' }}
              className={
                item.mode ? `${styles.cdsDropdown}` : `${styles.cdsDropdown}`
              }
              options={item.options}
              disabled={item.disabled}
              data-testid={`formItemSelect-${item.key}`}
              suffixIcon={item.selectSuffixIcon}
              optionFilterProp='label'
            />
          )}
          {item.element === 'InputSecret' && (
            <Input.Password
              aria-label={item.key}
              className={styles.cdsTextfieldField}
              placeholder={item.placeholder}
              type='input'
              data-testid={`formItemInput-${item.key}`}
              maxLength={item.maxLength}
              prefix={item.prefix}
              onPressEnter={handlerOnPressEnter}
            />
          )}
          {item.element === 'InputNumber' && (
            <InputNumber
              aria-label={item.key}
              className={styles.cdsNumberField}
              data-testid={`formItemInput-${item.key}`}
              min={item.min}
              max={item.max}
              disabled={item.disabled}
              onPressEnter={handlerOnPressEnter}
            />
          )}
        </>
      </Form.Item>
      {Boolean(errorMemo.length) && (
        <p className={styles.errorMessages}>{errorMemo[0]}</p>
      )}
    </div>
  )
}
