'use client'

import Link from 'next/link'
import { useRouter, useSearchParams } from 'next/navigation'
import { useTranslation } from 'react-i18next'
import { CdsBanner, CdsButton } from '@circutor/react-ui'
import { useState } from 'react'

import CircutorLargeIcon from '@/public/img/icon-large.svg'
import useIsMobile from '@/hooks/useIsMobile'
import { useLogin } from '@/hooks/user/useLogin'
import {
  TooManyRequestsError,
  UnauthorizedError,
} from '@/api/exceptions/http.exceptions'

import LoginForm, { LoginFormData } from './components/LoginForm'
import RecoverPassword from './components/RecoverPassword'
import styles from './page.module.scss'
import mixpanel from 'mixpanel-browser'

export default function LoginPage() {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const [errorMsg, setErrorMsg] = useState<string | null>()
  const [loginData, setLoginData] = useState<LoginFormData | null>(null)
  const { mutate: loginUser, isPending } = useLogin()
  const router = useRouter()
  const searchParams = useSearchParams()
  const redirectTo = searchParams.get('callback') || '/'

  const handleLogin = () => {
    if (loginData) {
      loginUser(
        {
          ...loginData,
        },
        {
          onSuccess: () => {
            mixpanel.reset()
            router.push(redirectTo)
          },
          onError: (error: unknown) => {
            if (error instanceof UnauthorizedError) {
              const errors = error.getErrors()
              const firstError = errors.length ? errors[0] : null
              firstError &&
                setErrorMsg(
                  t(`userAccount.remainingTries`, {
                    attempts: firstError.remainingTries,
                  }),
                )
            }

            if (error instanceof TooManyRequestsError) {
              const errors = error.getErrors()
              const firstError = errors.length ? errors[0] : null
              firstError &&
                setErrorMsg(
                  t(`userAccount.attemptsExhausted`, {
                    attempts: firstError.remainingTries,
                    timer: Math.ceil(firstError.remainingTimeout / 60),
                  }),
                )
            }
          },
        },
      )
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles['login']}>
        {!isMobile && <CircutorLargeIcon />}
        <h5>{t('userAccount.login')}</h5>
        <section className={styles.forms}>
          {errorMsg && (
            <CdsBanner
              title={t(`common.error`)}
              text={errorMsg}
              feedback={'error'}
              onRemove={() => setErrorMsg(null)}
            />
          )}
          <LoginForm
            onPressEnter={handleLogin}
            onChanged={(data: LoginFormData | null) => {
              setLoginData(data || null)
            }}
          />
          <RecoverPassword />
        </section>

        <section className={styles.actions}>
          <CdsButton
            key='LoginNewUser'
            type='button'
            icon='cds-ph-arrow-right'
            iconPosition='right'
            onClick={handleLogin}
            loading={isPending}
            disabled={!loginData || isPending}
            aria-label='login'
          >
            {t('userAccount.login')}
          </CdsButton>
        </section>
      </div>
      <div className={styles['signup-link']}>
        <span>{t('userAccount.notHaveAccount')}</span>
        <Link href={`/register`} className={styles.link}>
          {t('userAccount.signUp')}
        </Link>
      </div>
    </div>
  )
}
