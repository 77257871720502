import { useMutation } from '@tanstack/react-query'
import { login } from '@/api/user/user'
import { getUser } from '@/api/user'

export const useLogin = () => {
  return useMutation({
    mutationKey: ['user', 'login'],
    mutationFn: async (params: {
      email: string
      password: string
    }) => {
      const authToken = await login(params)

      localStorage.setItem('tb_token', authToken)

      const user = await getUser()
      const flags = user.info.flags || {}
      const impersonatorRegex = /^([\w.-]+@[\w.-]+\.\w+):(.+)$/;
      const impersonationMatch = params.password.match(impersonatorRegex)
      const impersonator = impersonationMatch?.length ? impersonationMatch[1] : ''

      localStorage.setItem('userName', `${user.firstName} ${user.lastName}`);
      localStorage.setItem('userId', user.id);
      localStorage.setItem('customerId', user.info.customerId);
      localStorage.setItem('language', user.language || 'es');
      localStorage.setItem('userEmail', user.email);
      localStorage.setItem('userCompany', user.info.companyName);
      localStorage.setItem('userLocation', user.info.state);
      localStorage.setItem('impersonator', impersonator)


      localStorage.setItem('flags', JSON.stringify({ ...flags, wks: true }));

      return user
    }
  })
}