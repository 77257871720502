import { config } from "@/config/config"
import { HttpError } from "@/api/exceptions/http.exceptions"

export type RequestConfigExtended = RequestInit & { url: string }

const refreshToken = async (tbToken: string) => {

  const response = await fetch(`${config.apiUrl}/user/refreshToken`, {
    headers: {
      Authorization: `Bearer ${tbToken}`,
    }
  })

  if (!response.ok) {
    throw response
  }

  const responseBody = await response.json()
  const newToken = responseBody.data.token

  if (typeof window !== 'undefined') {
    localStorage.setItem('tb_token', newToken)
  }

  return newToken
}

const isNeedRefreshToken = (jwtRaw: string) => {
  try {
    const parsedJWT = JSON.parse(atob(jwtRaw.split(".")[1]));
    const MARGING_TIME_MS = 10 * 1000

    return (parsedJWT.exp * 1000) - MARGING_TIME_MS < Date.now()
  } catch (e) {
    return null
  }
}

const extendRequestWithAuthConfig = async (requestConfig: RequestConfigExtended, setContentType: Boolean) => {
  let tbToken = ''
  if (typeof window !== 'undefined') {
    tbToken = localStorage.getItem('tb_token') || ''
    tbToken = isNeedRefreshToken(tbToken) ? await refreshToken(tbToken) : tbToken
  }
  let headers: Record<string, any> = {
    ...requestConfig.headers,
  }

  if (tbToken) {
    headers = {
      ...headers,
      Authorization: `Bearer ${tbToken}`
    }
  }

  if (setContentType) {
    headers['Content-Type'] = headers['Content-Type'] || 'application/json'
  }

  return { ...requestConfig, headers }
}

export default extendRequestWithAuthConfig