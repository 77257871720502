import { config } from '@/config/config'

import extendRequestWithAuthConfig from "./interceptors/extendWithAuthConfig.interceptor"
import responseToJsonInterceptor from "./interceptors/responseToJson.interceptor"
import { BadRequestError, ConflictError, ForbiddenError, HttpError, NotFoundError, TooManyRequestsError, UnauthorizedError } from '../exceptions/http.exceptions'

const PUBLIC_ENDPOINTS = ['user/register', 'user/login', 'user/resetPasswordByEmail', 'user/resetPassword']

const httpClientWithInterceptors = {
  fetch: async (path: string, optionsRaw?: RequestInit, setContentType = true) => {

    const needAuthHeaders = !PUBLIC_ENDPOINTS.find(route => path.includes(route))
    try {
      const basicConfig = { url: `${config.apiUrl}/${path}`, ...optionsRaw }

      const requestConfig = needAuthHeaders ? await extendRequestWithAuthConfig(basicConfig, setContentType) : basicConfig

      const response = await fetch(requestConfig.url, requestConfig)

      if (response.status >= 300) {
        throw (response)
      }

      const contentType = response.headers?.get('Content-Type') || ''
      if (contentType.includes('application/zip') || contentType.includes('text/html') || contentType.includes('application/pdf')) {
        return response
      }

      return responseToJsonInterceptor(response)
    } catch (error: any) {
      if (error?.status === 400) {
        throw BadRequestError.create(error?.status, await responseToJsonInterceptor(error))
      }

      if (error?.status === 401) {
        if (needAuthHeaders) {
          const queryParam = new URLSearchParams({
            callback: `${window.location.href}`,
          })
          window.location.href = `/login?${queryParam}`
        }
        throw UnauthorizedError.create(error?.status, await responseToJsonInterceptor(error))
      }

      if (error?.status === 403) {
        throw ForbiddenError.create(error?.status, await responseToJsonInterceptor(error))
      }

      if (error?.status === 404) {
        throw NotFoundError.create(error?.status, await responseToJsonInterceptor(error))
      }

      if (error?.status === 409) {
        throw ConflictError.create(error?.status, await responseToJsonInterceptor(error))
      }

      if (error?.status === 429) {
        throw TooManyRequestsError.create(error?.status, await responseToJsonInterceptor(error))
      }

      throw HttpError.create(error?.status)
    }
  }
}

export default httpClientWithInterceptors
