export class HttpError extends Error {
  status: number
  errors: any[] = []


  constructor(status: number, message: string = 'Unexpected http error') {
    super(message)
    this.status = status || 500
  }

  static create(status: number, _?: any) {
    return new this(status)
  }

  getErrors() {
    return this.errors
  }
}

export class UnauthorizedError extends HttpError {
  errors: any[] = []

  static create(status: number, body: Partial<{ details: any, error: any, errors: any[] }>) {
    const httpError = new this(status, 'Unauthorized Error')
    const error = body.error || body.details ? [body.error || body.details] : null
    const errors = body.errors || []
    httpError.errors = error || errors
    return httpError
  }
}

export class NotFoundError extends HttpError {
  errors: any[] = []

  static create(status: number, body: Partial<{ details: any, error: any, errors: any[] }>) {
    const httpError = new this(status, 'Not found Error')
    const error = body.error ? [body.error] : null
    const errors = body.errors || []
    httpError.errors = error || errors
    return httpError
  }

}

export class ForbiddenError extends HttpError {
  static create(status: number, body: Partial<{ details: any, error: any, errors: any[] }>) {
    const httpError = new this(status, 'Forbidden Error')
    const error = body.error || body.details ? [body.error || body.details] : null
    const errors = body.errors || []
    httpError.errors = error || errors
    return httpError
  }
}

export class BadRequestError extends HttpError {
  errors: any[] = []

  static create(status: number, body: Partial<{ details: any, error: any, errors: any[] }>) {
    const httpError = new this(status, 'Bad request Error')
    const error = body.error || body.details ? [body.error || body.details] : null
    const errors = body.errors || []
    httpError.errors = error || errors
    return httpError
  }

}

export class ConflictError extends HttpError {
  errors: any[] = []

  static create(status: number, body: Partial<{ details: any, error: any, errors: any[] }>) {
    const httpError = new this(status, 'Conflict Error')
    const error = body.error || body.details ? [body.error || body.details] : null
    const errors = body.errors || []
    httpError.errors = error || errors
    return httpError
  }
}
export class TooManyRequestsError extends HttpError {
  static create(status: number, body: Partial<{ details: any, error: any, errors: any[] }>) {
    const httpError = new this(status, 'Too Many Requests Error')
    const error = body.error || body.details ? [body.error || body.details] : null
    const errors = body.errors || []
    httpError.errors = error || errors
    return httpError
  }
}