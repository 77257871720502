import { config } from '@/config/config'
import { ChangePasswordUser, User, UserResponse } from '@/types/users'

export async function getUser(): Promise<User> {
  let tbToken = ''
  if (typeof window !== 'undefined') {
    tbToken = localStorage.getItem('tb_token') || ''
  }

  let userResponse = await fetch(`${config.apiUrl}/user`, {
    headers: {
      Authorization: `Bearer ${tbToken}`,
    },
  })

  const parsedUsers: UserResponse = await userResponse.json()
  const user: User = parsedUsers?.user

  return user ?? []
}

export async function updateUser({ user }: { user: User }) {
  let tbToken = ''
  if (typeof window !== 'undefined') {
    tbToken = localStorage.getItem('tb_token') || ''
  }
  const body = user
  const data = await fetch(`${config.apiUrl}/user`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${tbToken}`,
    },
    body: JSON.stringify(body),
  })
  return data
}

export async function changePasswordUser({
  password,
}: {
  password: ChangePasswordUser
}) {
  let tbToken = ''
  if (typeof window !== 'undefined') {
    tbToken = localStorage.getItem('tb_token') || ''
  }
  const body = {
    currentPassword: password.currentPassword,
    newPassword: password.newPassword,
  }
  const data = await fetch(`${config.apiUrl}/user/changePassword`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${tbToken}`,
    },
    body: JSON.stringify(body),
  })
  if (!data.ok) {
    const errorData = await data.json()
    throw new Error(JSON.stringify(errorData))
  }
  return data
}

export async function deleteUser({ }) {
  let tbToken = ''
  if (typeof window !== 'undefined') {
    tbToken = localStorage.getItem('tb_token') || ''
  }
  const data = await fetch(`${config.apiUrl}/user`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${tbToken}`,
    },
  })
  if (!data.ok) {
    const errorData = await data.json()
    throw new Error(JSON.stringify(errorData))
  }
  return data
}

export async function logout() {
  let tbToken = ''
  if (typeof window !== 'undefined') {
    tbToken = localStorage.getItem('tb_token') || ''
  }
  const data = await fetch(`${config.apiUrl}/user/logout`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${tbToken}`,
    },
  })
  if (!data.ok) {
    const errorData = await data.json()
    throw new Error(JSON.stringify(errorData))
  }
  return data
}
