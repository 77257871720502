import sanitize from 'sanitize-html'

type TextHtmlToNodeProps = {
  textHtml: string
}

export default function TextHtmlToNode({ textHtml }: TextHtmlToNodeProps) {
  return (
    <p
      dangerouslySetInnerHTML={{
        __html: sanitize(textHtml, {
          allowedClasses: {
            a: ['cds-link'],
          },
        }),
      }}
    />
  )
}
